import PropTypes from "prop-types"
import React from "react"

const SEO = ({ title }) => {
  return (
    <>
      <meta charSet="utf-8" />
      <title>{title} || E-commerce Agency</title>

      <meta
        name="description"
        content="Experienced E-Commerce developer"
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
    </>
  )
}
SEO.propTypes = {
  title: PropTypes.string,
}

export default SEO
