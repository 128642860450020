import React, { useState, useEffect } from "react";
import PortfolioData from "../../data/portfolio/PortfolioData.json";
import PortfolioItem from "./PortfolioItem";
import { FaSpinner } from "react-icons/fa";


const filters = [
    {
      id: 1,
      text: "All",
    },
    {
      id: 2,
      text: "React",
    },
    {
      id: 3,
      text: "Vue",
    }
   
];

const alldata = PortfolioData;
const PortfolioOne = ({ Column ,data}) => {
   
    console.log(data,'lower level data')
    const [getAllItems] = useState(data);
    const [dataVisibleCount, setDataVisibleCount] = useState(6);
    const [dataIncrement] = useState(6) ;
    const [noMorePost, setNoMorePost] = useState(false);
    const [activeFilter, setActiveFilter] = useState("");
    const [visibleItems, setVisibleItems] = useState([]);
    useEffect(() => {
        setActiveFilter(filters[0].text.toLowerCase());
        setVisibleItems(getAllItems.filter((item) => item.node.id_ <= dataVisibleCount));
    }, []);

    const handleChange = (e) => {
        e.preventDefault();
        setActiveFilter(e.target.textContent.toLowerCase());
        let tempData;
        if (e.target.textContent.toLowerCase() === filters[0].text.toLowerCase()) {
          tempData = getAllItems.filter((data) => data.node.id_ <= dataVisibleCount);
        } else {
          tempData = getAllItems.filter(
            (data) =>
              data.node.text === e.target.textContent.toLowerCase() 
            
          );
        }
        setVisibleItems(tempData);
    };

    const handleLoadmore = (e) => {
        e.preventDefault();
        let tempCount = dataVisibleCount + dataIncrement;
        if (dataVisibleCount >= getAllItems.length) {
            setNoMorePost(true);
        } else {
            setDataVisibleCount(tempCount);
            if (activeFilter === filters[0].text.toLowerCase()) {
                setVisibleItems(getAllItems.filter((data) => data.node.id_ <= tempCount));
            }else {
                setVisibleItems(
                    getAllItems.filter(
                      (data) => data.node.text === activeFilter && data.node.id_ <= tempCount
                    )
                );
            }
        }
    }
    
    return (
        <>
        
            <div className="row row--15">
                {visibleItems.map((item) => (
                    <div key={item.node.id_} className={Column}>
                        <PortfolioItem portfolio={item.node} />
                    </div>
                ))}
            </div>

            <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rwt-load-more text-center mt--50">
                   
                    </div>
                </div>
            </div>
        </>
    )
}

export default PortfolioOne;
