import React from 'react';
import SEO from "../../common/SEO";

import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import PortfolioOne from "./PortfolioOne";

const PortfolioThreeColumn = (data) => {
  
    return (
        <>
            <SEO title="React Work|| Edgardo Medina" />
        
                <BreadcrumbOne 
                    title="Projects"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Projects"
                />
                <div className="main-content">
                    <div className="rwt-portfolio-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    
                                </div>
                            </div>
                            <PortfolioOne data={data.data} Column="col-lg-4 col-md-6 mt--30 portfolio"  />
                        </div>
                    </div>
                </div>
           
        </>
    )
}
export default PortfolioThreeColumn;
