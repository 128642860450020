import React from 'react';
import SEO from "../common/SEO";

import PortfolioThreeColumn from '../elements/portfolio/PortfolioThreeColumn';
import HeaderTwo from '../common/header/HeaderTwo';
import BlogGridView from '../components/blog/BlogGridView'
import { useStaticQuery, graphql } from 'gatsby';






const Experience = ({data}) => {
   
    const tech =[];
    return (
        <>
            <SEO title="React | Edgardo Medina" />
            <HeaderTwo/>
            <PortfolioThreeColumn data={tech}/>
        </>
    )
}
export default  Experience;